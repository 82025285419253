/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Icon } from '../../Icons/BaseIcon';
import { Container, Menu, MenuItems, Link } from './DropDownMenu.styles';
import { NavLink } from 'react-router-dom';
import { HandleOutsideDropdown } from '../../../utils/utils';

const DropDownMenu = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const { ref, isComponentVisible } = HandleOutsideDropdown();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    if (!isComponentVisible) {
      toggleMenu();
    }
  }, [isComponentVisible, toggleMenu]);

  return (
    <Container onClick={toggleMenu} className='dropdown'>
      <Icon
        className='dropdown'
        src={props.picture ? props.picture : '/images/logo.png'}
        height={24}
        width={80}
        // borderRadius='50%'
      />
      <Menu className={showMenu ? 'visible' : null} ref={ref}>
        <MenuItems>
          <a href='https://locgame.io'>
            <Link className='dropdown'>
              <Icon src='/images/token.png' height={18} width={18} />
              Home
            </Link>
          </a>
          <NavLink
            exact
            to='/'
            activeStyle={{
              fontWeight: '700',
              backgroundColor: 'rgba(255,255,255,.2',
            }}
          >
            <Link className='dropdown'>
              <Icon src='/images/credit-cards.svg' height={18} width={18} />
              Market
            </Link>
          </NavLink>
          <a href='http://earlyaccess.locgame.io'>
            <Link className='dropdown'>
              <Icon src='/images/cards.svg' height={18} width={18} />
              Game
            </Link>
          </a>
        </MenuItems>
      </Menu>
    </Container>
  );
};

export default DropDownMenu;
