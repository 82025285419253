import styled from 'styled-components';

export const Main = styled.main`
  position: relative;
  width: 100%;

  @media screen and (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Section = styled.div`
  position: relative;
  display: flex;

  padding: ${({ padding }) => (padding ? padding : '2rem')};
  width: 100%;

  .card {
    width: 250px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;

    @media screen and (max-width: 611px) {
      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (max-width: 611px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
  }
`;

export const Header = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '1425px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margin }) => (margin ? margin : '0 0 0 2rem')};

  border-bottom: ${({ theme }) => theme.borderMuted};

  .filter-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
  }

  .filterMenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
    cursor: pointer;
    font: ${({ theme }) => theme.font.small};
    color: ${({ theme }) => theme.lightBlue};

    .close {
      margin-left: 5px;
      font-size: 9px;
    }
  }

  @media screen and (max-width: 725px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
