import React from 'react';
import { Container } from './LibraryHeader.styles';

const LibraryHeader = (props) => {
  return (
    <Container>
      <div className='column info'>
        <p className='title'>Legends of Crypto</p>
        <h2 className='title-heading'>Playing Card Info</h2>
        <p className='title-tag'>
          4 directional attacks, health, and special abilities
        </p>

        <ul className='attributes'>
          <li>
            <div className='attr-value'>10</div>
            <p className='attr-name'>Innovation (Left Attack)</p>
          </li>
          <li>
            <div className='attr-value'>10</div>
            <p className='attr-name'>Influence (Top Attack)</p>
          </li>
          <li>
            <div className='attr-value'>0</div>
            <p className='attr-name'>Community (Right Attack)</p>
          </li>
          <li>
            <div className='attr-value'>0</div>
            <p className='attr-name'>Dev Skill (Bottom Attack)</p>
          </li>
          <li>
            <div className='attr-value'>20</div>
            <p className='attr-name'>Health</p>
          </li>
        </ul>
      </div>
      <div className='column card-image'>
        <img src='/images/lamboguy.png' alt='game card' />
      </div>
    </Container>
  );
};

export default LibraryHeader;
