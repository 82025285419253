import './App.css';
import React, { memo, useState, useContext, useEffect, useCallback, Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Browser, Route, Switch } from 'react-router-dom';
import { lightTheme, darkTheme, GlobalStyles } from './themes';
import Navbar from './components/Navbar/Navbar.component';
import Footer from './components/Footer/Footer.component';
import useWeb3 from './utils/useWeb3';
import { UserContext } from './context/UserContext';
import CardLibrary from './pages/CardLibrary/CardLibrary';
import { fetchCurrentRate, fetchUserById, updateUserById } from './utils/api';
import config from './config';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { USER_REJECTED } from './constants';
import Spinner from './components/Spinner/Spinner';
import useIntersection from './utils/useIntersection';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal.component';
import ScrollToTop from './utils/scrollToTop';
import LoginPage from './pages/LoginPage/LoginPage.component';
const ListingPage = React.lazy(() => import('./pages/ListingPage/ListingPage'));
const ItemPage = React.lazy(() => import('./pages/ItemPage/ItemPage'));
const Homepage = React.lazy(() => import('./pages/Homepage/Homepage'));
const SignupPage = React.lazy(() => import('./pages/SignupPage/SignupPage.component'));
const ProfilePage = React.lazy(() => import('./pages/ProfilePage/ProfilePage'));
const ProductPage = React.lazy(() => import('./pages/ProductPage/ProductPage'));
const AllItems = React.lazy(() => import('./pages/AllItemsPage/AllItems'));
const SacrificePage = React.lazy(() => import('./pages/SacrificePage/SacrificePage'));
const CollectionPage = React.lazy(() => import('./pages/CollectionPage/CollectionPage'));
const PasswordReset = React.lazy(() => import('./components/PasswordReset/PasswordReset.component'));
const PasswordChange = React.lazy(() => import('./pages/PasswordChange/PasswordChange.component'));
const PrivacyPage = React.lazy(() => import('./pages/PrivacyPage/PrivacyPage'));
const ConfirmContactPage = React.lazy(() => import('./pages/ConfirmContactPage/ConfirmContact'));
const SupportPage = React.lazy(() => import('./pages/Support/SupportPage'));
const EmailVerifyPage = React.lazy(() => import('./pages/EmailVerifyPage/EmailVerify'));

Amplify.configure(config.cognito);


function App() {
  const { web3, provider, setProvider } = useWeb3();
  const [theme] = useState('dark');
  const [user, setUser, walletAddress, setWalletAddress] =
    useContext(UserContext);
  const [loginModal, setLoginModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [rate, setRate] = useState(null);
  const [maticRate, setMaticRate] = useState(null);
  const [declineWallet, setDeclineWallet] = useState(false);
  const [setRef, visible] = useIntersection({});

  const toggleLoginModal = useCallback(() => {
    setLoginModal(!loginModal);
  }, [loginModal]);

  const getUser = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const token = cognitoUser.signInUserSession.idToken.jwtToken;
      const roles =
        cognitoUser.signInUserSession.accessToken.payload['cognito:groups'];
      localStorage.setItem('token', token);
      const user = await fetchUserById(cognitoUser?.username);
      user.avatar = user.avatar || 'user.svg';
      user.roles = roles;
      setUser(user);
      return user;
    } catch (err) {
      setUser(null);
    }
  }, [setUser]);

  const signOut = useCallback(() => {
    window.location.assign(config.cognito.oauth.redirectSignOut);
    setUser(null);
    localStorage.removeItem('token');
  }, [setUser]);

  const fetchRate = useCallback(async () => {
    const rate = {};
    rate.rate = await fetchCurrentRate('USDT', 'LOCG');
    rate.maticRate = await fetchCurrentRate("MATIC", 'USDT');
    if (rate.rate) {
      rate.timestamp = Date.now();
      setRate(parseFloat(rate.rate));
      setMaticRate(parseFloat(rate.maticRate));
      localStorage.setItem('rate', JSON.stringify(rate));
    }
  }, []);

  useEffect(() => {
    let tempUser;

    async function checkRate() {
      let rate = JSON.parse(localStorage.getItem('rate'));
      if (rate) {
        rate.timestamp = new Date(rate.timestamp);
        let currentDate = new Date();
        if (currentDate.getTime() - rate.timestamp.getTime() > 3600000) {
          fetchRate();
        } else {
          setRate(rate.rate);
          setMaticRate(rate.maticRate);
        }
      } else {
        fetchRate();
      }
    }
    checkRate();
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log(event);
      switch (event) {
        case 'signIn':
        case 'autoSignIn':
        case 'federatedSignIn':
          tempUser = getUser();
          break;
        case 'oAuthSignOut':
        case 'signOut':
          signOut();
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log({ level: 'Warning', message: 'Sign in failure', data });
          break;
        default:
          break;
      }
    });
    if (!tempUser) {
      getUser();
    }

  }, [getUser, signOut, fetchRate]);

  const setWalletAttribute = useCallback(async () => {
    if (!user || user?.wallet?.toLowerCase() === walletAddress?.toLowerCase()) {
      return;
    } else {
      await updateUserById({ ...user, wallet: walletAddress });
      setUser(user => ({ ...user, wallet: walletAddress }));
      const currentUser = await Auth.currentAuthenticatedUser();
      Auth.updateUserAttributes(currentUser, {
        'custom:wallet': walletAddress?.toLowerCase() || '',
      });
    }
  }, [user, walletAddress, setUser]);

  useEffect(() => {
    if (user && user.email !== 'testaccount@locgame.io') {
      setWalletAttribute();
    }
  }, [walletAddress, user, setWalletAttribute]);

  useEffect(() => {
    if (web3 && user && !declineWallet) {
      async function fetchWallet() {
        try {
          if (window.ethereum)
            await provider.request({ method: 'eth_requestAccounts' });
          else if (provider !== config.ethRpc) {
            await provider.enable();
            setProvider(provider);
          }
          let accounts = await web3.eth.getAccounts();
          setWalletAddress(accounts[0].toLowerCase());
        } catch (err) {
          if (err.code === USER_REJECTED) {
            setDeclineWallet(true);
            return;
          }
          console.log('Error', err.code);
        }
      }
      fetchWallet();
    }
  }, [user, setWalletAddress, web3, declineWallet, provider, setProvider]);

  // const themeToggler = useCallback(() => {
  //   theme === 'light' ? setTheme('dark') : setTheme('light');
  // }, [theme]);



  const toggleConfirmation = useCallback(() => {
    setConfirmation(!confirmation);
  }, [confirmation]);

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      <Browser>
        <ScrollToTop  >
          <div className="topDiv" ref={setRef}></div>
          {loginModal && <LoginPage sendClose={toggleLoginModal} />}
          <Navbar
            loginModal={loginModal}
            toggleLoginModal={toggleLoginModal}
            web3={web3}
            background={visible}
          />
          {confirmation && <ConfirmationModal sendClose={toggleConfirmation} />}
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route
                path='/support'
                render={(props) => <SupportPage {...props} />}
              />
              <Route
                path='/collection'
                render={(props) => <CollectionPage {...props} />}
              />
              <Route
                path='/signup'
                render={(props) => <SignupPage {...props} />}
              />
              <Route
                path='/verify'
                exact
                render={(props) => <EmailVerifyPage {...props} />}
              />
              <Route
                path='/contact-confirmation'
                render={(props) => <ConfirmContactPage {...props} />}
              />
              <Route
                path='/privacy'
                render={(props) => <PrivacyPage {...props} />}
              />
              <Route
                path='/profile/:tab?'
                exact
                render={(props) => (
                  <ProfilePage
                    {...props}
                    web3={web3}
                    rate={rate}
                    maticRate={maticRate}
                    provider={provider}
                    setProvider={setProvider} />
                )}
              />
              <Route
                path='/library'
                render={(props) => <CardLibrary {...props} />}
              />
              <Route
                path='/all-items'
                render={(props) => (
                  <AllItems {...props} maticRate={maticRate} />
                )}
              />
              {/* <Route
                path='/admin'
                render={(props) => (
                  <AuthGuard toggleLoginModal={toggleLoginModal}>
                    {user && user.roles && user.roles.includes('Admin') && (
                      <AdminPanel />
                    )}
                  </AuthGuard>
                )}
              /> */}
              <Route
                path='/products/:id'
                render={(props) =>
                  web3 && (
                    <ProductPage
                      {...props}
                      toggleLoginModal={toggleLoginModal}
                      toggleConfirmation={toggleConfirmation}
                      rate={rate}
                      web3={web3}
                    />
                  )
                }
              />
              <Route
                path='/list/:id'
                render={(props) =>
                  web3 && (
                    <ListingPage
                      {...props}
                      toggleLoginModal={toggleLoginModal}
                      toggleConfirmation={toggleConfirmation}
                      rate={rate}
                      web3={web3}
                    />
                  )
                }
              />
              <Route
                path='/sacrifice/:id'
                render={(props) =>
                  web3 && (
                    <SacrificePage
                      {...props}
                      toggleLoginModal={toggleLoginModal}
                      toggleConfirmation={toggleConfirmation}
                      rate={rate}
                      web3={web3}
                    />
                  )
                }
              />
              <Route
                path='/item/:id'
                render={(props) =>
                  <ItemPage
                    {...props}
                    toggleLoginModal={toggleLoginModal}
                  />
                }
              />
              <Route
                path='/password-reset'
                render={(props) => <PasswordReset />}
              />
              <Route
                path='/password-change'
                exact
                render={(props) => (<PasswordChange {...props} />)}
              />
              <Route
                path='/cards'
                exact
                render={(props) => (
                  <Homepage
                    {...props}
                    rate={rate}
                    maticRate={maticRate}
                    toggleConfirmation={toggleConfirmation}
                    toggleLogin={toggleLoginModal}
                    selectedTab={1}
                  />
                )}
              />
              <Route
                path='/'
                exact
                render={(props) => (
                  <Homepage
                    {...props}
                    rate={rate}
                    maticRate={maticRate}
                    toggleConfirmation={toggleConfirmation}
                    toggleLogin={toggleLoginModal}
                    user={user}
                  />
                )}
              />
            </Switch>
          </Suspense>
          <Footer />
        </ScrollToTop>
      </Browser>
    </ThemeProvider>
  );
}

export default memo(App)


