import React from 'react';
import { Container } from './Checkbox.styles';

const Checkbox = (props) => {
  return (
    <Container {...props} id='container' onChange={null}>
      <input
        type={props.type || 'checkbox'}
        name={props.name}
        id={props.name}
        value={props.value}
        {...props}
      />
      <label htmlFor={props.id} id='label' onChange={null}>
        <span></span>
        {props.label}
      </label>
    </Container>
  );
};

export default Checkbox;
