
export function validateLogin(values) {
  let errors = {};

  // if (!values.username) {
  //   errors.username = 'Username or Email is Required to Sign In ';
  // }

  if (!values.password) {
    errors.password = 'Password is Required';
  }

  if (!values.email || !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'A Valid Email is Required.';
  }

  return errors;
}


