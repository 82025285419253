/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Icon } from '../Icons/BaseIcon';
import { Container, Menu, MenuItems, Link } from './UserMenu.styles';
import { NavLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { HandleOutsideDropdown } from '../../utils/utils';
import config from '../../config';

const UserMenu = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const { ref, isComponentVisible } = HandleOutsideDropdown();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    if (!isComponentVisible) {
      toggleMenu();
    }
  }, [isComponentVisible, toggleMenu]);

  return (
    <Container onClick={toggleMenu} className='dropdown'>
      <Icon
        className='dropdown'
        src={
          props.avatar
            ? `http://static-files.locgame.io/public/${props.avatar}`
            : 'http://static-files.locgame.io/public/user.svg'
        }
        height={42}
        width={42}
        borderRadius='50%'
      />
      {/* <Username className='dropdown'>{props.username} </Username> 
      <Icon src='/images/line-arrow-right.svg' className='nav-icon' />*/}

      <Menu className={showMenu ? 'visible' : null} ref={ref}>
        <MenuItems>
          <NavLink
            exact
            to='/profile'
            activeStyle={{
              fontWeight: '700',
              backgroundColor: 'rgba(255,255,255,.2',
            }}
          >
            <Link className='dropdown'>
              <Icon src='/images/user.svg' height={18} width={18} />
              User Profile
            </Link>
          </NavLink>
          <NavLink
            to='/support'
            activeStyle={{
              fontWeight: '700',
              backgroundColor: 'rgba(255,255,255,.2',
            }}
          >
            <Link className='dropdown'>
              <Icon src='/images/chat.svg' height={18} width={18} />
              Support
            </Link>
          </NavLink>
          {props.user?.roles?.includes('Admin') && (
            <a href={config.adminUrl}>
              <Link className='dropdown'>
                <Icon src='/images/settings.svg' height={18} width={18} />
                Admin Panel
              </Link>
            </a>
          )}
          <Link
            className='dropdown'
            onClick={() => {
              Auth.signOut();
            }}
          >
            <Icon src='/images/log-out.svg' height={18} width={18} /> Log out
          </Link>
        </MenuItems>
      </Menu>
    </Container>
  );
};

export default UserMenu;
