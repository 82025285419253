import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  inset: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(2px);
  z-index: 10000;

  img {
    height: 200px;
    width: 200px;
    margin: 2rem;
  }
`;

const Spinner = (props) => {
  return ReactDOM.createPortal(
    <Container>
      <h2>{props.msg1}</h2>
      <img src='/images/spinner.gif' alt='' />
      <h2>{props.msg2}</h2>
    </Container>,
    document.getElementById('portal')
  );
};

export default Spinner;
