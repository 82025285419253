/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { getPacks } from './api';
import { parseNftAttributes } from './utils';

const useFilter = (data, type = 'card') => {
  const [packs, setPacks] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    sort: type === 'nft' ? 'OLDEST_ORDERS' : 'newest',
    abilities: false,
    standard: false,
    prestige: false,
    elite: false,
    superElite: false,
    legend: false,
    common: false,
    genesis: false,
    blockchain: false,
    cryptoCeleb: false,
    defi: false,
    metaverse: false,
    mcw: false,
    apollo: false,
    djsoda: false,
    wolf: false,
    sacrifice: false,
    influence: { min: 0, max: 100 },
    innovation: { min: 0, max: 100 },
    devSkills: { min: 0, max: 100 },
    community: { min: 0, max: 100 },
    health: { min: 0, max: 100 },
    impact: { min: 0, max: 100 },
  });

  useEffect(() => {
    getPacks()
      .then((packs) => setPacks(packs.values))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (data && type === 'card') {
      filterCardData();
    }
    if (data && type === 'nft') {
      filterNftData();
    }
    if (data && type === 'pack') {
      filterPackData();
    }
  }, [filters, data]);

  const resetFilters = () => {
    setFilters({
      search: '',
      sort: 'newest',
      abilities: false,
      standard: false,
      prestige: false,
      elite: false,
      superElite: false,
      legend: false,
      common: false,
      genesis: false,
      blockchain: false,
      cryptoCeleb: false,
      defi: false,
      metaverse: false,
      mcw: false,
      apollo: false,
      djsoda: false,
      wolf: false,
      sacrifice: false,
      influence: { min: 0, max: 100 },
      innovation: { min: 0, max: 100 },
      devSkills: { min: 0, max: 100 },
      community: { min: 0, max: 100 },
      health: { min: 0, max: 100 },
      impact: { min: 0, max: 100 },
    });
  };

  const filterCardData = () => {
    let filteredSet = data;

    // card with an ability only
    if (filters.abilities) {
      filteredSet = filteredSet.filter((card) => card.ability);
    }

    // filter by visual rarity if required
    if (
      filters.standard ||
      filters.prestige ||
      filters.elite ||
      filters.superElite ||
      filters.legend
    ) {
      const choiceArr = [];
      if (filters.standard) choiceArr.push('001');
      if (filters.prestige) choiceArr.push('002');
      if (filters.elite) choiceArr.push('003');
      if (filters.superElite) choiceArr.push('004');
      if (filters.legend) choiceArr.push('005');
      filteredSet = filteredSet.filter((card) =>
        choiceArr.includes(card.vRarity)
      );
    }

    // filter by Pack if required
    if (
      filters.genesis ||
      filters.blockchain ||
      filters.cryptoCeleb ||
      filters.defi ||
      filters.metaverse ||
      filters.mcw ||
      filters.apollo ||
      filters.djsoda ||
      filters.wolf ||
      filters.sacrifice
    ) {
      const choiceArr = [];
      if (filters.genesis) choiceArr.push('000');
      if (filters.blockchain) choiceArr.push('001');
      if (filters.cryptoCeleb) choiceArr.push('002');
      if (filters.defi) choiceArr.push('003');
      if (filters.metaverse) choiceArr.push('004');
      if (filters.mcw) choiceArr.push('005');
      if (filters.apollo) choiceArr.push('006');
      if (filters.djsoda) choiceArr.push('007');
      if (filters.wolf) choiceArr.push('008');
      if (filters.sacrifice) choiceArr.push('009');

      filteredSet = filteredSet.filter((card) =>
        choiceArr.includes(card.pack.toLowerCase())
      );
    }

    // filter by gameplay rarity if required
    if (
      filters.common ||
      filters.uncommon ||
      filters.rare ||
      filters.legendary ||
      filters.mythic
    ) {
      const choiceArr = [];
      if (filters.common) choiceArr.push('001');
      if (filters.uncommon) choiceArr.push('002');
      if (filters.rare) choiceArr.push('003');
      if (filters.legendary) choiceArr.push('004');
      if (filters.mythic) choiceArr.push('005');

      filteredSet = filteredSet.filter((card) =>
        choiceArr.includes(card.rarity.toLowerCase())
      );
    }

    // filter by influence
    if (
      (filters.influence.min && parseInt(filters.influence.min) > 0) ||
      (filters.influence.max && parseInt(filters.influence.max) < 100)
    ) {
      let min = parseInt(filters.influence.min) || 0;
      let max = parseInt(filters.influence.max) || 100;
      filteredSet = filteredSet.filter(
        (card) => card.influence >= min && card.influence <= max
      );
    }
    // filter by innovation
    if (
      (filters.innovation.min && parseInt(filters.innovation.min) > 0) ||
      (filters.innovation.max && parseInt(filters.innovation.max) < 100)
    ) {
      let min = parseInt(filters.innovation.min) || 0;
      let max = parseInt(filters.innovation.max) || 100;
      filteredSet = filteredSet.filter(
        (card) => card.innovation >= min && card.innovation <= max
      );
    }
    // filter by devSkills
    if (
      (filters.devSkills.min && parseInt(filters.devSkills.min) > 0) ||
      (filters.devSkills.max && parseInt(filters.devSkills.max) < 100)
    ) {
      let min = parseInt(filters.devSkills.min) || 0;
      let max = parseInt(filters.devSkills.max) || 100;
      filteredSet = filteredSet.filter(
        (card) => card.devSkills >= min && card.devSkills <= max
      );
    }
    // filter by community
    if (
      (filters.community.min && parseInt(filters.community.min) > 0) ||
      (filters.community.max && parseInt(filters.community.max) < 100)
    ) {
      let min = parseInt(filters.community.min) || 0;
      let max = parseInt(filters.community.max) || 100;
      filteredSet = filteredSet.filter(
        (card) => card.community >= min && card.community <= max
      );
    }
    // filter by health
    if (
      (filters.health.min && parseInt(filters.health.min) > 0) ||
      (filters.health.max && parseInt(filters.health.max) < 100)
    ) {
      let min = parseInt(filters.health.min) || 0;
      let max = parseInt(filters.health.max) || 100;
      filteredSet = filteredSet.filter(
        (card) => card.health >= min && card.health <= max
      );
    }
    // filter by impact
    if (
      (filters.impact.min && parseInt(filters.impact.min) > 0) ||
      (filters.impact.max && parseInt(filters.impact.max) < 100)
    ) {
      let min = parseInt(filters.impact.min) || 0;
      let max = parseInt(filters.impact.max) || 100;
      filteredSet = filteredSet.filter(
        (card) => card.impact >= min && card.impact <= max
      );
    }

    if (filters.sort === 'oldest') {
      filteredSet = filteredSet.sort(
        (a, b) => Date.parse(a.created) - Date.parse(b.created)
      );
    }
    if (filters.sort === 'newest') {
      filteredSet = filteredSet.sort(
        (a, b) => Date.parse(b.created) - Date.parse(a.created)
      );
    }

    if (filters.search) {
      let re = new RegExp(filters.search, 'i');
      filteredSet = filteredSet.filter((card) => re.test(card.name));
    }

    setFilteredData([...filteredSet]);
  };

  const filterNftData = () => {
    let filteredSet = data;

    // // filter by visual rarity if required
    if (
      filters.standard ||
      filters.prestige ||
      filters.elite ||
      filters.superElite ||
      filters.legend
    ) {
      const choiceArr = [];
      if (filters.standard) choiceArr.push('001');
      if (filters.prestige) choiceArr.push('002');
      if (filters.elite) choiceArr.push('003');
      if (filters.superElite) choiceArr.push('004');
      if (filters.legend) choiceArr.push('005');

      filteredSet = filteredSet.filter((nft) => {
        const card = parseNftAttributes(nft);
        return choiceArr.includes(card['visual rarity'].toLowerCase());
      });
    }

    // // filter by Pack if required
    if (
      filters.genesis ||
      filters.blockchain ||
      filters.cryptoCeleb ||
      filters.defi ||
      filters.metaverse ||
      filters.mcw ||
      filters.apollo ||
      filters.djsoda ||
      filters.wolf ||
      filters.sacrifice
    ) {
      const choiceArr = [];
      if (filters.genesis) choiceArr.push(packs['000'].toLowerCase());
      if (filters.blockchain) choiceArr.push(packs['001'].toLowerCase());
      if (filters.cryptoCeleb) choiceArr.push(packs['002'].toLowerCase());
      if (filters.defi) choiceArr.push(packs['003'].toLowerCase());
      if (filters.metaverse) choiceArr.push(packs['004'].toLowerCase());
      if (filters.mcw) choiceArr.push(packs['005'].toLowerCase());
      if (filters.apollo) choiceArr.push('006');
      if (filters.djsoda) choiceArr.push('007');
      if (filters.wolf) choiceArr.push('008');
      if (filters.sacrifice) choiceArr.push('009');
      filteredSet = filteredSet.filter((nft) => {
        const card = parseNftAttributes(nft);
        return choiceArr.includes(card?.pack?.toLowerCase());
      });
    }

    // // filter by gameplay rarity if required
    // if (
    //   filters.common ||
    //   filters.uncommon ||
    //   filters.rare ||
    //   filters.legendary ||
    //   filters.mythic
    // ) {
    //   const choiceArr = [];
    //   if (filters.common) choiceArr.push('001');
    //   if (filters.uncommon) choiceArr.push('002');
    //   if (filters.rare) choiceArr.push('003');
    //   if (filters.legendary) choiceArr.push('004');
    //   if (filters.mythic) choiceArr.push('005');

    //   filteredSet = filteredSet.filter((card) =>
    //     choiceArr.includes(card.rarity.toLowerCase())
    //   );
    // }

    // // filter by influence
    if (
      (filters.influence.min && parseInt(filters.influence.min) > 0) ||
      (filters.influence.max && parseInt(filters.influence.max) < 100)
    ) {
      let min = parseInt(filters.influence.min) || 0;
      let max = parseInt(filters.influence.max) || 100;
      filteredSet = filteredSet.filter((nft) => {
        const card = parseNftAttributes(nft);
        return card.influence >= min && card.influence <= max;
      });
    }

    // // filter by innovation
    if (
      (filters.innovation.min && parseInt(filters.innovation.min) > 0) ||
      (filters.innovation.max && parseInt(filters.innovation.max) < 100)
    ) {
      let min = parseInt(filters.innovation.min) || 0;
      let max = parseInt(filters.innovation.max) || 100;
      filteredSet = filteredSet.filter((nft) => {
        const card = parseNftAttributes(nft);
        return card.innovation >= min && card.innovation <= max;
      });
    }

    // filter by devSkills
    if (
      (filters.devSkills.min && parseInt(filters.devSkills.min) > 0) ||
      (filters.devSkills.max && parseInt(filters.devSkills.max) < 100)
    ) {
      let min = parseInt(filters.devSkills.min) || 0;
      let max = parseInt(filters.devSkills.max) || 100;
      filteredSet = filteredSet.filter((nft) => {
        const card = parseNftAttributes(nft);
        return card['dev skills'] >= min && card['dev skills'] <= max;
      });
    }

    // // filter by community
    if (
      (filters.community.min && parseInt(filters.community.min) > 0) ||
      (filters.community.max && parseInt(filters.community.max) < 100)
    ) {
      let min = parseInt(filters.community.min) || 0;
      let max = parseInt(filters.community.max) || 100;
      filteredSet = filteredSet.filter((nft) => {
        const card = parseNftAttributes(nft);
        return card.community >= min && card.community <= max;
      });
    }

    // // filter by health
    if (
      (filters.health.min && parseInt(filters.health.min) > 0) ||
      (filters.health.max && parseInt(filters.health.max) < 100)
    ) {
      let min = parseInt(filters.health.min) || 0;
      let max = parseInt(filters.health.max) || 100;
      filteredSet = filteredSet.filter((nft) => {
        const card = parseNftAttributes(nft);
        return card['top wealth'] >= min && card['top wealth'] <= max;
      });
    }

    // // filter by impact
    // if (
    //   (filters.impact.min && parseInt(filters.impact.min) > 0) ||
    //   (filters.impact.max && parseInt(filters.impact.max) < 100)
    // ) {
    //   let min = parseInt(filters.impact.min) || 0;
    //   let max = parseInt(filters.impact.max) || 100;
    //   filteredSet = filteredSet.filter(
    //     (card) => card.impact >= min && card.impact <= max
    //   );
    // }

    // if (filters.sort === 'oldest') {
    //   filteredSet = filteredSet.sort(
    //     (a, b) => Date.parse(a.created) - Date.parse(b.created)
    //   );
    // }
    // if (filters.sort === 'newest') {
    //   filteredSet = filteredSet.sort(
    //     (a, b) => Date.parse(b.created) - Date.parse(a.created)
    //   );
    // }

    if (filters.search) {
      let re = new RegExp(filters.search, 'i');
      filteredSet = filteredSet.filter((card) =>
        re.test(card.tokenMetadata.name)
      );
    }

    setFilteredData([...filteredSet]);
  };

  const filterPackData = () => {
    let filteredSet = data;

    // // filter by Pack if required
    if (
      filters.genesis ||
      filters.blockchain ||
      filters.cryptoCeleb ||
      filters.defi ||
      filters.metaverse ||
      filters.mcw ||
      filters.apollo ||
      filters.djsoda ||
      filters.wolf ||
      filters.sacrifice
    ) {
      const choiceArr = [];
      if (filters.genesis) choiceArr.push('genesis pack');
      if (filters.genesis) choiceArr.push('genesis prestige');
      if (filters.genesis) choiceArr.push('genesis elite');
      if (filters.blockchain) choiceArr.push(packs['001'].toLowerCase());
      if (filters.cryptoCeleb) choiceArr.push(packs['002'].toLowerCase());
      if (filters.defi) choiceArr.push(packs['003'].toLowerCase());
      if (filters.metaverse) choiceArr.push(packs['004'].toLowerCase());
      if (filters.mcw) choiceArr.push(packs['005'].toLowerCase());
      if (filters.apollo) choiceArr.push('apollo pack');
      if (filters.apollo) choiceArr.push('apollo prestige');
      if (filters.apollo) choiceArr.push('apollo elite');
      if (filters.apollo) choiceArr.push('apollo super elite');
      if (filters.djsoda) choiceArr.push('dj soda super elite');
      if (filters.djsoda) choiceArr.push('dj soda legendary');
      if (filters.wolf) choiceArr.push('wolf of crypto super elite');
      if (filters.wolf) choiceArr.push('wolf of crypto legendary');
      if (filters.sacrifice) choiceArr.push('sacrifice legendary');
      filteredSet = filteredSet.filter((pack) => {
        return choiceArr.includes(pack?.title?.toLowerCase());
      });
    }
    if (filters.search) {
      let re = new RegExp(filters.search, 'i');
      filteredSet = filteredSet.filter((pack) => re.test(pack.title));
    }
    if (filters.sort === 'lowest') {
      filteredSet = filteredSet.sort((a, b) => a.price - b.price);
    }
    if (filters.sort === 'highest') {
      filteredSet = filteredSet.sort((a, b) => b.price - a.price);
    }
    if (filters.sort === 'newest') {
      filteredSet = filteredSet.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );
    }

    setFilteredData([...filteredSet]);
  };

  return { filteredData, setFilteredData, filters, setFilters, resetFilters };
};

export default useFilter;
