import React, { useEffect, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router';
import {
  ModalWrapper,
  Modal,
  CloseBtn,
  Header,
  Content,
} from './ConfirmationModal.styles';
import Button from '../Button/Button.component';
import { HandleOutsideClick } from '../../utils/utils';
import config from '../../config';
import useGA from '../../utils/useGA';

const ConfirmationModal = (props) => {
  const etherscanURL = config.etherscanURL;
  const { sendClose } = props;
  const { ref, isComponentVisible } = HandleOutsideClick();
  const history = useHistory();
  const { purchaseComplete } = useGA();
  const order = useMemo(() => history.location.order, [history.location.order]);

  useEffect(() => {
    return () => {
      if (order) {
        purchaseComplete(order);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isComponentVisible) {
      sendClose();
    }
  }, [isComponentVisible, sendClose]);

  const handleClick = useCallback(() => {
    sendClose();
    history.push('profile');
  }, [history, sendClose]);

  return ReactDOM.createPortal(
    <ModalWrapper>
      <Modal ref={ref}>
        <CloseBtn onClick={sendClose} />
        <Header>
          <h3>Confirmation</h3>
          <p>
            Your cards are being minted and will be available in My Items soon.
          </p>
        </Header>
        {order && (
          <Content>
            <div className='img-container'>
              <img src={order.product.images[2]} alt='' />
            </div>
            <h2>{order.product.title}</h2>
            <span className='qty'>
              Quantity: {order.qty}{' '}
              {order.qty === 1 ? 'Pack with ' : 'Packs with '}
              {order.product.items.length * order.qty} cards total
            </span>
            <h3>Payment Initiated</h3>
            <p className='hashTitle'>Transaction Hash:</p>
            <a
              href={`${etherscanURL}${order.txHash}`}
              target='_blank'
              rel='noreferrer'
            >
              <p className='hash'>{order.txHash}</p>
            </a>
            <Button width={270} onClick={handleClick}>
              See My Items
            </Button>
          </Content>
        )}
      </Modal>
    </ModalWrapper>,
    document.getElementById('portal')
  );
};

export default React.memo(ConfirmationModal);
