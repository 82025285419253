import styled from 'styled-components';

export const Container = styled.div`
  margin: 1rem 0;
  padding-bottom: 10px;
  display: ${(props) => (props.inline === 'true' ? `flex` : null)};
  align-items: ${(props) => (props.inline === 'true' ? `center` : null)};
  justify-content: ${(props) =>
    props.inline === 'true' ? `space-between` : null};
  border-bottom: ${({ theme }) => theme.borderMuted};

  select {
    display: inline-block;
    appearance: none;
    margin: 0.5rem 1rem 0 0;
    background: none;
    width: 90px;
    border: ${({ theme }) => theme.borderMuted};
    border-radius: 4px;
    color: ${({ theme }) => theme.fontColor};
    padding: 8px;
    font: ${({ theme }) => theme.font.small};
  }

  option {
    background: ${({ theme }) => theme.primaryColor};
  }
`;

export const IconDiv = styled.div`
  display: inline-block;
  position: relative;
  height: ${(props) => `${props.iconHeight}px`};
  width: ${(props) => (props.inline === 'true' ? `unset` : '90%')};
  margin-right: ${(props) => (props.inline === 'true' ? `10px` : 'unset')};

  img {
    height: ${(props) => `${props.iconHeight}px`};
    width: ${(props) => `${props.iconWidth}px`};
    margin: 0;
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    height: ${(props) => `${props.iconHeight}px`};
    width: ${(props) => `${props.iconWidth}px`};
    font: ${({ theme }) => theme.font.small};

    line-height: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
