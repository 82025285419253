import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  ${(props) =>
    props.inline === 'true'
      ? `align-items: center;`
      : `flex-direction: column;
  justify-content: center;`}

  input {
    display: none;
  }

  input:checked + label span:after {
    content: url('/images/tick.svg');
    position: absolute;
    width: 20px;
    height: 20px;
    left: -3px;
    top: -3px;
  }

  input:checked + label span {
    background: ${({ theme }) => theme.fontColor};
  }

  p {
    font: ${({ theme }) => theme.font.small};
    font-weight: 500;
    line-height: 10px;
  }

  span {
    height: 20px;
    width: 20px;
    margin: ${({ margin }) => (margin ? margin : '8px 8px 8px 0')};
    display: inline-block;
    position: relative;
    border: 1px solid ${({ theme }) => theme.fontColor};
    border-radius: 4px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  label {
    display: flex;
    position: relative;
    ${(props) =>
      props.inline
        ? `align-items: center;`
        : `flex-direction: column;
  justify-content: center;`}

    font: ${({ theme }) => theme.font.small};
    cursor: pointer;
  }
`;
