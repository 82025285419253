import { Auth, API } from 'aws-amplify';
import Web3 from 'web3';
import config from '../config';
import { toChecksumAddress } from './useWeb3';

// ======================== OpenMarket through Backend API ============================
//OpenMarket calls
export const fetchNFT = async (chain, contractAddress, tokenId) => {
  const apiName = 'openMarketplace';
  const path = `/openmarket/tokens/${chain}/${contractAddress}/${tokenId}`;

  return await API.get(apiName, path);
};
export const fetchNFTHistory = async (chain, contractAddress, tokenId) => {
  const apiName = 'openMarketplace';
  const path = `/openmarket/trades/${chain}/${contractAddress}/${tokenId}`;

  return await API.get(apiName, path);
};

export const fetchAllListedNFTs = async (
  { chain = config.maticNetworkName,
    contractAddress = config.nftAddress,
    from = 0,
    limit = 10,
    hasOrder = true,
    onlyValidMetadata = true,
    orderBy,
    priceFrom,
    priceTo,
    erc20PaymentToken } = {}
) => {
  const apiName = 'openMarketplace';
  const path = `/openmarket/tokens/${chain}`;
  if (orderBy === 'newest')
    orderBy = 'OLDEST_ORDERS';
  const options = {
    queryStringParameters: {
      limit,
      from,
      hasOrder,
      contractAddress,
      onlyValidMetadata,
      orderBy,
      priceFrom,
      priceTo,
      erc20PaymentToken
    },
  };
  return await API.get(apiName, path, options);
};

export const fetchUsersListedNFTs = async (
  tokenOwner,
  chain = config.maticNetworkName,
  contractAddress = config.nftAddress,
  hasOrder = true
) => {
  const apiName = 'openMarketplace';
  const path = `/openmarket/tokens/${chain}`;
  const options = {
    queryStringParameters: {
      tokenOwner,
      hasOrder,
      contractAddress
    },
  };

  return await API.get(apiName, path, options);
};

export const postSignedOrder = async (chain, order) => {
  const apiName = 'openMarketplace';
  const path = `/openmarket/orders/${chain}`;
  const myInit = {
    body: order,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };

  return await API.post(apiName, path, myInit);
};

export const updateTokenInfo = async (chain, contractAddress, tokenId) => {
  const apiName = 'openMarketplace';
  const path = `/openmarket/commands/update-token/${chain}/${contractAddress}/${tokenId}`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };

  return await API.post(apiName, path, myInit);
};


export const addToken = async (chain, contractAddress, tokenId) => {

  const apiName = 'openMarketplace';
  const path = `/openmarket/admin/addtoken/${chain}/${contractAddress}/${tokenId}`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {},
  };
  return await API.post(apiName, path, myInit);
};

// ======================== Marketplace Backend API ============================

// Enum

// Order Calls

export async function fetchOrders(userId) {
  let limit = 20;
  let skip = 0;
  let orders = [];
  const apiName = 'marketplace';
  const path = `/order/user/${userId}`;
  const params = {
    response: true,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    queryStringParameters: {
      skip,
    },
  };
  try {
    let res = await API.get(apiName, path, params);
    orders = [...res.data.data];
    while (res.data.data.length > 0) {
      params.queryStringParameters.skip = res.data.skip + limit;
      res = await API.get(apiName, path, params);
      orders = [...orders, ...res.data.data];
    }
  } catch (err) {
    console.log(err);
  }
  return orders;
}


// Product Related Calls

export const getProductTypes = async () => {
  const apiName = 'marketplace';
  const path = '/enum/productType';
  const res = await API.get(apiName, path);
  return res.values;
};
export const getProductEditions = async () => {
  const apiName = 'marketplace';
  const path = '/enum/edition';
  const res = await API.get(apiName, path);
  return res.values;
};

export const getPacks = async () => {
  const apiName = 'marketplace';
  const path = '/enum/pack';
  return await API.get(apiName, path);
};

export const getRarities = async () => {
  const apiName = 'marketplace';
  const path = '/enum/rarity';
  return await API.get(apiName, path);
};

export const getVRarities = async () => {
  const apiName = 'marketplace';
  const path = '/enum/vRarity';
  return await API.get(apiName, path);
};

export const getBanishedIds = async () => {
  const apiName = 'marketplace';
  const path = '/enum/banishedIds';
  const result = await API.get(apiName, path);
  return result.values;
};

export const getDiscount = async (code) => {
  const apiName = 'marketplace';
  const path = `/discount/${code}`;
  return await API.get(apiName, path);
};

export const applyDiscount = (total, discount) => {
  if (!discount?.active || discount?.isPending) return total;
  switch (discount.type) {
    case 'percent':
      return (total - (total * discount.value));
    case 'dollar':
      return (total - discount.value);
    default:
      return (total);
  }
};



// Product Calls
export const getProducts = async () => {
  let skip = 0;
  let limit = 20;
  let products;
  let hasMore = true;
  const apiName = 'marketplace';
  const path = '/product';
  const options = {
    queryStringParameters: {
      limit,
      skip,
      count: 'true',
    },
  };

  try {
    let res = await API.get(apiName, path, options);
    products = res.data;
    while (hasMore) {
      skip++;
      const options = {
        queryStringParameters: { limit, count: true, skip: limit * skip },
      };
      res = await API.get(apiName, path, options);
      products = [...products, ...res.data];
      if (res.data.length < 1) {
        hasMore = false;
      }
    }
    return products;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch Products',
      error: err,
    });
  }

};



// Card Calls

export async function getCards() {
  let skip = 0;
  let limit = 200;
  let cards;
  let hasMore = true;
  const apiName = 'marketplace';
  const path = '/card';
  const options = {
    response: true,
    queryStringParameters: { limit, count: true, skip: limit * skip },
  };

  try {
    let res = await API.get(apiName, path, options);
    cards = res.data.data;
    while (hasMore) {
      skip++;
      const options = {
        response: true,
        queryStringParameters: { limit, count: true, skip: limit * skip },
      };
      res = await API.get(apiName, path, options);
      cards = [...cards, ...res.data.data];
      if (res.data.data.length < 1) {
        hasMore = false;
      }
    }
    return cards;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch Cards',
      error: err,
    });
  }
}

export async function getHeroes() {

  const apiName = 'marketplace';
  const path = '/card/mintcount';

  try {
    let res = await API.get(apiName, path);
    const { heroes } = res;
    return heroes;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch Heroes',
      error: err,
    });
  }
}
// Rate Calls

function getRate(from, to) {
  const apiName = 'marketplace';
  const path = '/rate';
  const myInit = {
    queryStringParameters: {
      symbol: from,
      convert: to,
    },
  };

  return API.get(apiName, path, myInit);
}

export const fetchCurrentRate = async (from, to) => {
  try {
    const data = await getRate(from, to);
    let rate = data.data.quote[to].price;
    return parseFloat(rate);
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch exchange rate',
      error: err,
    });
    return 0;
  }
};

export const fetchAllCurrentRates = async (from, to) => {
  try {
    const data = await getRate(from, to);
    let rates = data.data.quote;
    return rates;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch multiple exchange rates',
      error: err,
    });
    return 0;
  }
};

// Gas Price Calls

export const getGasPrice = async (chain) => {
  const apiName = 'marketplace';
  const path = chain ? `/gas-price/${chain}` : `/gas-price`;

  try {
    let { result } = await API.get(apiName, path);
    return result.FastGasPrice;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch gas price',
      error: err,
    });
  }
};

export const getMaxPriorityFee = async (chain) => {
  const apiName = 'marketplace';
  const path = chain ? `/gas-priority/${chain}` : `/gas-priority/polygon`;
  const options = {
    body: {
      jsonrpc: '2.0',
      method: 'eth_maxPriorityFeePerGas',
      params: [],
      id: 'latest',
    },
  };

  try {
    let { result } = await API.post(apiName, path, options);
    result = Web3.utils.hexToNumberString(result);
    return result;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch priority fee',
      error: err,
    });
  }
};

//NFT Order Calls

//get all orders

// get buyer orders by email

export const postNftOrder = async (orderInfo) => {
  const apiName = 'marketplace';
  const path = `/nft-order`;
  const options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: orderInfo,
  };

  return await API.post(apiName, path, options);
};
export const updateNftOrder = async (orderId) => {
  const apiName = 'marketplace';
  const path = `/nft-order/${orderId}`;
  const options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };

  return await API.put(apiName, path, options);
};

// ======================== External Requests ============================

// Get token metadata

export const getTokenMetadata = async (
  tokenId,
  contractAddress = config.nftAddress
) => {
  let metadataUrl;
  if (contractAddress.toLowerCase() !== config.nftAddress) {
    return;
    // To Do - fetch metadata url
  } else {
    metadataUrl = `https://meta.locgame.io/${toChecksumAddress(
      config.nftAddress
    )}/${tokenId}`;
  }
  return await API.get('global', metadataUrl);
};


// Stripe Payment Calls

export const createPayment = async (options = {}) => {
  const { orderId, productType } = options;
  const apiName = 'marketplace';
  const path = `/payment`;
  const callOptions = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      orderId,
      productType,
    }
  };
  return await API.post(apiName, path, callOptions);
};

// User Calls

/***************************************************************
* Get single User from Database
* @param {string} userId
* @return {Promise} Promise resolves to user.
****************************************************************/
export const fetchUserById = async (userId) => {
  const apiName = 'marketplace';
  const path = `/user/${userId}`;
  const callOptions = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    }
  };
  return await API.get(apiName, path, callOptions);
};

/***************************************************************
* Update User In Database
* @param {Object} user
* @return {Promise} Promise resolves to updated user.
****************************************************************/
export const updateUserById = async (user) => {
  const apiName = 'marketplace';
  const path = `/user/${user._id}`;
  const callOptions = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: user
  };
  return await API.patch(apiName, path, callOptions);
}; 