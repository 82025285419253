import ReactGA from 'react-ga4';


export default function useGA() {


  return {
    pageview: (page, title) => {
      ReactGA.send({ hitType: "pageview", page, title });
    },
    purchaseStart: (order, total, path) => {
      if (!order || !total) {
        console.log("Missing Arguments");
        return;
      }
      ReactGA.gtag('event', 'purchase_start', {
        category: "User",
        action: "Purchase Start",
        label: `${order?.product.title}`,
        value: total,
        order_id: order?._id,
        item_id: order?.product._id,
        discount: order?.discountCode,
        item_name: order?.product.title,
        payment_type: order?.currency,
        user_id: order?.iduser,
        path,
      });

    },
    purchaseFailed: (order, reason, path) => {
      if (!order) {
        console.log("Missing Arguments");
        return;
      }
      ReactGA.gtag("event", 'purchase_failed', {
        category: "User",
        action: "Purchase Failed",
        label: `${order?.product.title}`,
        order_id: order?._id,
        item_id: order?.product._id,
        discount: order?.discountCode,
        item_name: order?.product.title,
        payment_type: order?.currency,
        user_Id: order?.iduser,
        reason,
        path,
      });
    },
    purchaseComplete: (order) => {
      ReactGA.gtag('event', 'purchase_complete', {
        action: 'Purchase Complete',
        category: 'User',
        order_id: order._id,
        item_id: order.product._id,
        value: order.value,
        discount: order.discountCode,
        item_name: order.product.title,
        payment_type: order.currency,
        user_id: order.iduser,
      });
      ReactGA.gtag('event', 'conversion', { 'send_to': 'AW-10896502630/bwoACPPM4JMYEObe7cso' });
    },
    signupStart: (referrerId, email) => {
      ReactGA.gtag('event', 'signup_start', {
        action: 'Signup Start',
        category: 'User',
        method: referrerId ? 'Referral' : 'Email',
        email,
        referrer_id: referrerId ? referrerId : null,
      });
    },
    signupComplete: (referrerId, email) => {
      ReactGA.gtag('event', 'sign_up', {
        action: 'Signup Complete',
        category: 'User',
        method: referrerId ? 'Referral' : 'Email',
        email,
        referrer_id: referrerId ? referrerId : null,
      });
    },
    signupFailed: (referrerId, email, err) => {
      ReactGA.gtag('event', 'signup_failed', {
        action: 'Signup Failed',
        category: 'User',
        method: referrerId ? 'Referral' : 'Email',
        email,
        referrer_id: referrerId ? referrerId : null,
        error: err,
      });
    }
  };
}

