import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: #0e0c16;
  display: flex;
  position: relative;
  min-height: calc(100vh + 70px);
  justify-content: space-around;
  padding-top: ${({ theme }) => theme.navHeight};

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url('/images/posterBG.jpg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    z-index: 0;
    opacity: 20%;
  }

  .column {
    width: 40%;
    padding: 2rem;
    background: none;
    z-index: 1;
  }

  .card-image {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 80%;
    }
  }

  .info {
    padding-left: 6rem;
  }

  .title {
    font: 3.75rem/4rem 'Dark Twenty';
    margin-bottom: 1.5rem;
    text-shadow: 0 0 10px #0ad6ca;
  }
  .title-heading {
    font-size: 4.25rem;
    font-weight: 800;
    margin-bottom: 2.25rem;
  }
  .title-tag {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  ul {
    list-style: none;
  }
  li {
    padding: 10px 0;
    display: flex;
    align-items: center;
    max-width: 410px;
    :nth-child(1) {
      color: #ef7a11;
    }
    :nth-child(2) {
      color: #cee741;
    }
    :nth-child(3) {
      color: #fac711;
    }
    :nth-child(4) {
      color: #79bf4c;
    }
    :nth-child(5) {
      color: #d4006d;
    }
  }
  .attr-value {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 75px;
    border: 2px solid #964af4;
    border-radius: 10px;
    box-shadow: inset 0 0 20px 0 #964af4;
    font-size: 1.75rem;
    font-weight: 900;
  }

  .attr-name {
    font-size: 1.5rem;
    font-weight: 400;
    margin-left: 1rem;
    color: #fff;
  }

  .btn-link {
    display: inline-block;
    margin: 0 0 3rem 2.5rem;
  }

  @media screen and (max-width: 1742px) {
    .column {
      width: 48%;
    }
  }
  @media screen and (max-width: 1455px) {
    .column {
      width: 45%;
    }
    .title {
      font: 2.75rem/4rem 'Dark Twenty';
      margin-bottom: 1;
    }
    .title-heading {
      font-size: 3rem;
      font-weight: 800;
      margin-bottom: 1.75rem;
    }
    .title-tag {
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 1.5rem;
    }
    .btn-link {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 1455px) {
    .info {
      padding-left: 2rem;
    }
    &:after {
      top: 120px;
    }
  }

  @media screen and (max-width: 865px) {
    flex-direction: column;
    align-items: center;

    .column {
      width: 80%;
    }
  }
  @media screen and (max-width: 580px) {
    .column {
      width: 100%;
      padding: 2rem 1rem;
    }
    .title {
      font: 2.5rem/3rem 'Dark Twenty';
      margin-bottom: 1;
    }
    .title-heading {
      font-size: 2rem;
      font-weight: 800;
      margin-bottom: 1.75rem;
    }
    .title-tag {
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 1.5rem;
    }
    .btn-link {
      margin-bottom: 1rem;
    }
    .attr-value {
      height: 65px;
      width: 65px;
      border-radius: 10px;
      box-shadow: inset 0 0 20px 0 #964af4;
      font-size: 1.5rem;
      font-weight: 900;
    }

    .attr-name {
      font-size: 1.25rem;
      font-weight: 400;
      margin-left: 1rem;
      color: #fff;
    }
  }
`;
