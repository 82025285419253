/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../context/UserContext';
import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import config from '../config';
import { USER_REJECTED } from '../constants';

const useWeb3 = () => {
  const ethereum = window.ethereum;
  const [web3, setWeb3] = useState(null);
  const [user, , , setWalletAddress] = useContext(UserContext);
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (ethereum) setProvider(ethereum);
    else if (navigator.userAgent.includes('Mobile')) {
      let wcProvider = new WalletConnectProvider({
        infuraId: '59ab074c808946a48eb6bc5b6b2aafcd',
      });
      setProvider(wcProvider);
    } else {
      setProvider(config.ethRpc);
    }
  }, []);

  useEffect(() => {
    if (provider) {
      createWeb3();
    }
  }, [provider]);

  async function createWeb3() {
    let instance;
    try {
      if (!ethereum && user) await provider.enable();
    } catch (err) {
      if (err.code === USER_REJECTED) return;
      console.log(err);
    }
    try {
      instance = new Web3(provider);
      setWeb3(instance);
      if (provider !== config.ethRpc) {
        provider.on('accountsChanged', (accounts) => {
          if (accounts[0]) setWalletAddress(accounts[0].toLowerCase());
          else setWalletAddress(null);
        });
      }
    } catch (err) {
      if (err.code === USER_REJECTED) return;
      console.log(err);
    }
  }

  return { web3, provider, setProvider };
};

export default useWeb3;

export const verifyTransaction = async (txHash) => {
  try {
    let res = await axios.get(`/erc20/${txHash}`);
    if (res.data.success) alert(res.data.tokens);
    if (!res.data.success) alert(res.data.reason);
  } catch (err) {
    console.log(err);
  }
};

export const fromWei = (amount, to = 'ether') => {
  const web3 = new Web3();
  return web3.utils.fromWei(amount, to);
};
export const toWei = (amount, to = 'ether') => {
  const web3 = new Web3();
  return web3.utils.toWei(amount, to);
};

export const toChecksumAddress = (address) => {
  const web3 = new Web3();
  if (web3.utils.isAddress(address)) {
    return web3.utils.toChecksumAddress(address);
  }
  return null;
};
