import styled from 'styled-components';

export const Nav = styled.nav`
  background: ${({ background, theme }) =>
    background ? 'transparent' : theme.bgColor};
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 50px 0 30px;
  display: flex;
  align-items: center;
  min-height: ${({ theme }) => theme.navHeight};
  z-index: 30;

  .wrapper {
    display: flex;
    align-items: center;
    margin-right: 3rem;
  }

  .login-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .signup {
    height: 45px;
    border: 3px solid rgba(255, 255, 255, 0.15);
    border-radius: 0 0 0 6em;
    width: 150px;
    padding: 0 1rem 0;
    transform: translateX(2rem);
    font: ${({ theme }) => theme.font.button};
    color: ${({ theme }) => theme.fontMuted};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.15);
      color: ${({ theme }) => theme.fontColor};
    }
  }

  // nav custom settings
  .nav-link {
    font: 500 14px/24px TTHoves;
    color: ${({ theme }) => theme.fontColor};
    display: flex;
    position: relative;
    align-items: center;
    padding: 0 15px;
    height: 100%;
    max-height: 60px;
    cursor: pointer;
    text-transform: uppercase;
    opacity: 0.6;

    &:hover {
      background-color: ${({ theme }) => theme.navHoverBg};
      opacity: 1;
    }

    &.game-dd:hover {
      background-color: ${({ theme }) => theme.navHoverBg};
    }
    &.more-dd:hover {
      background-color: ${({ theme }) => theme.navHoverBg};
    }
    &.game-dd:hover .nav-drop-down.game {
      display: flex;
    }
    &.more-dd:hover .nav-drop-down.more {
      display: flex;
    }
  }

  .nav-icon {
    width: 10px;
    margin-left: 8px;
    transform: rotate(90deg);
  }

  .nav-drop-down {
    width: 260px;
    background: ${({ theme }) => theme.bgColor};
    position: absolute;
    top: -20px;
    left: 0;
    display: flex;
    flex-direction: column;
    transform: translateY(${({ theme }) => theme.navHeight});
    border: 2px solid ${({ theme }) => theme.borderMutedColor};

    a.nav-drop-down-link {
      width: 100%;
      padding: 15px;
      // border-bottom: 1px solid ${({ theme }) => theme.navBorder};
      text-transform: uppercase;

      &:hover {
        padding-left: 20px;
        background-color: ${({ theme }) => theme.navHoverBg};
        transition: padding-left 0.3s, background-color 0.3s;
      }
    }

    &.game,
    &.more {
      display: none;
    }
  }

  .mobile-toggle {
    width: ${({ theme }) => theme.navHeight};
    height: ${({ theme }) => theme.navHeight};
    display: none;
    align-items: center;
    justify-content: center;

    .nav-icon {
      margin: 0;
      width: 25px;
    }
    img {
      filter: invert(1);
      transform: rotate(0deg);
    }

    @media screen and (max-width: 1084px) {
      display: flex;
      padding: 0 1rem 0 1rem;
      flex-grow: 1;
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: 1023px) {
    justify-content: space-between;
    padding: 0 0.5rem 0 2rem;
  }

  @media screen and (max-width: 700px) {
    padding: 0 1rem 0 1rem;

    .loginBtn,
    .signup {
      width: 100px;
      height: 35px;
      font-size: 14px;
      line-height: 16px;
    }
    .signup {
      padding-right: 1.75rem;
    }
  }
  @media screen and (max-width: 390px) {
    padding: 0 1rem 0 1rem;

    .loginBtn,
    .signup {
      width: 100px;
      height: 35px;
      font-size: 12px;
      line-height: 14px;
    }
    .signup {
      padding-right: 1.75rem;
    }
  }
`;

export const Image = styled.img`
  width: 80px;
`;

export const Icon = styled.img`
  display: inline-block;
  height: 25px;
  width: 25px;
`;

export const NavMenuDesktop = styled.ul`
  display: flex;
  flex-grow: 1;
  height: ${({ theme }) => theme.navHeight};
  list-style: none;
  @media screen and (max-width: 1084px) {
    display: none;
  }
`;
export const NavMenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 1;
  background-color: ${({ theme }) => theme.bgColor};
  padding: 5rem 0 1rem;

  .nav-close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    height: 45px;
    width: 45px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px 30px 10px;
    border-radius: 50%;
  }

  ul.submenu {
    display: flex;
    flex-direction: column;
  }

  li {
    border-bottom: 2px solid #202125;
    height: 60px;
  }

  .sub-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    li {
      border-bottom: 2px solid #202125;
      height: 60px;
    }
  }

  .sub-heading {
    font: 500 14px/24px TTHoves;
    color: ${({ theme }) => theme.fontColor};
    padding: 0 15px;
    max-height: 60px;
    cursor: pointer;
    text-transform: uppercase;
    opacity: 0.6;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &:hover {
      background-color: ${({ theme }) => theme.navHoverBg};
      opacity: 1;
    }
  }

  @media screen and (max-width: 1084px) {
    display: flex;
  }
`;

// export const Link = styled.li`
//   display: inline-block;
//   font: ${({ theme }) => theme.font.button};
//   font-weight: 400;
//   margin: 0 1.5rem;
// `;
