/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import Button from '../Button/Button.component';
import { Nav, NavMenuDesktop } from './Navbar.styles';
import UserMenu from '../UserMenu/UserMenu.component';
import { NavLink, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import DropDownMenu from './DropDownMenu/DropDownMenu.component';

const Navbar = (props) => {
  const { toggleLoginModal } = props;
  const [user] = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let query = location.search.slice(1);
      if (query === 'login') {
        toggleLoginModal();
      }
    }
  }, [location.search]);

  return (
    <Nav background={props.background}>
      <div className='wrapper'>
        {/* <Image src='/images/logo.png' alt='Logo' /> */}
        <DropDownMenu />
      </div>
      <NavMenuDesktop />
      {!user ? (
        <div className='login-wrapper'>
          <NavLink to='/signup'>
            <div className='signup'>SIGN UP</div>
          </NavLink>

          <Button className='loginBtn' width='140' onClick={toggleLoginModal}>
            LOG IN
          </Button>
        </div>
      ) : (
        <div className='wrapper'>
          {/* <Notification /> */}
          <UserMenu
            user={user}
            username={`${user.firstName || ''} ${user.lastNamee || ''}`}
            avatar={user.avatar}
          />
        </div>
      )}
    </Nav>
  );
};

export default React.memo(Navbar);
