import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin: 1rem auto;
  //padding-top: ${({ theme }) => theme.navHeight};
  width: 100%;
  max-width: 1700px;
  display: flex;
  justify-content: center;
`;

export const Main = styled.main`
  position: relative;
  width: 100%;

  @media screen and (max-width: 1199px) {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
