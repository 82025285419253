import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { UserProvider } from './context/UserContext';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-HPLFQH745T');
window.gtag('config', 'AW-10896502630');


ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <App />
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
