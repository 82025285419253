import styled from 'styled-components';

const width = '180';
const height = '60';

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => (justify ? justify : 'center')};
  height: ${(props) =>
    !props.height
      ? `${height}px`
      : !isNaN(props.height)
      ? `${props.height}px`
      : props.height};
  width: ${(props) =>
    !props.width
      ? `${width}px`
      : !isNaN(props.width)
      ? `${props.width}px`
      : props.width};
  margin: ${({ margin }) => margin && margin};
  padding: ${({ padding }) => (padding ? padding : '14px 0')};
  background: ${({ theme, background }) =>
    background ? background : theme.mainGradient};
  border: none;
  border-radius: 8px;
  /* border-radius: ${(props) =>
    props.height ? `${props.height / 2}px` : `${height / 2}px`}; */
  color: ${({ theme }) => theme.fontColor};
  font: ${({ theme }) => theme.font.stdButton};
  cursor: pointer;
  isolation: isolate;

  &:disabled {
    background: rgba(255, 255, 255, 0.15);
    cursor: default;
  }

  &:hover {
    background: ${({ theme, background, disabled }) =>
      background || disabled ? '' : theme.hoverGradient};
    filter: ${({ background }) =>
      background && 'hue-rotate(15deg) brightness(85%)'};
  }
`;
