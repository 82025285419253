/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, CardFront, CardBack, Wrapper } from './GameCard.styles';

const GameCard = (props) => {
  const { card, newCard, autoFlip, flippedCardsSet } = props;
  const [show, setShow] = useState('hide');

  useEffect(() => {
    setTimeout(() => {
      setShow('show');
      if (autoFlip) {
        setShow('flip');
      }
    }, (newCard + 1) * 500);
  }, []);

  useEffect(() => {
    if (autoFlip) {
      setShow('flip');
    }
  }, [autoFlip]);

  if (newCard === undefined)
    return (
      <Wrapper>
        <Container {...props}>
          <a href={card.spotlight}>
            <CardFront {...props}>
              <img
                src={card.image ? card.image : '/images/no-card.png'}
                alt=''
              />
            </CardFront>
            <CardBack {...props}>
              <img
                src='https://static-files.locgame.io/d321db15-0c7e-4c53-999f-b1f31aceb06c'
                alt=''
              />
            </CardBack>
          </a>
        </Container>
      </Wrapper>
    );
  else
    return (
      <Wrapper>
        <Container
          className={
            show === 'show' ? 'show' : show === 'flip' ? 'flip' : 'hide'
          }
          onClick={() => {
            if (show === 'show') {
              setShow('flip');
              flippedCardsSet((prevState) => {
                return prevState + 1;
              });
            }
          }}
          {...props}
        >
          <CardFront {...props}>
            <img src={card.image} alt='' />
          </CardFront>
          <CardBack {...props}>
            <img
              src='https://static-files.locgame.io/d321db15-0c7e-4c53-999f-b1f31aceb06c'
              alt=''
            />
          </CardBack>
        </Container>
      </Wrapper>
    );
};

export default GameCard;
