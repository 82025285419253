import styled from 'styled-components';

const width = '180';
const height = '45';

export const ShadowDiv = styled.div`
  border-radius: ${({ theme }) => theme.buttonRadius};
  display: inline-block;
  position: relative;
  filter: ${({ theme }) => theme.dropShadow};
`;

export const ButtonContainer = styled.div`
  position: relative;
  margin: ${({ margin }) => (margin ? margin : null)};
  border-radius: ${({ theme }) => theme.buttonRadius};
  opacity: ${(props) => (props.disabled ? '50%' : 'null')};
  background: ${({ theme }) => theme.borderGradient};
  width: ${(props) => (props.width ? `${props.width}px` : `${width}px`)};
  height: ${(props) => (props.height ? `${props.height}px` : `${height}px`)};
  overflow: hidden;
  //clip-path: ${({ theme }) => theme.clipPath};
  /* 
  span:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    height: 3px;
    background: linear-gradient(to right, #a6ffcf, #06aa34);

    -webkit-animation: 2s animateTop linear infinite;
    animation: 2s animateTop linear infinite;
  }

  span:nth-child(2) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
    height: 3px;
    background: linear-gradient(to left, #a6ffcf, #06aa34);

    -webkit-animation: 2s animateBottom linear infinite;
    animation: 2s animateBottom linear infinite;
  }
  span:nth-child(3) {
    position: absolute;
    top: 0;
    left: 0;
    width: 25%;
    height: 100%;
    background: linear-gradient(to top, #a6ffcf, #06aa34);

    -webkit-animation: 2s animateLeft linear -0.8s infinite;
    animation: 2s animateLeft linear -0.8s infinite;
  }

  span:nth-child(4) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;
    height: 100%;
    background: linear-gradient(to bottom, #a6ffcf, #06aa34);

    -webkit-animation: 2s animateRight linear -0.8s infinite;
    animation: 2s animateRight linear -0.8s infinite;
  }

  @keyframes animateTop {
    0% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  @keyframes animateBottom {
    0% {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }
  @keyframes animateLeft {
    0% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
     
    }
    100% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      
    }
  }
  @keyframes animateRight {
    0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      
    }
    100% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
      
    }
  }

  ${({ animate }) =>
    !animate
      ? `span{
        display: none;
      }`
      : null}

  &:hover span {
    display: block;
  } */
`;

export const StyledButton = styled.button`
  height: ${(props) =>
    props.height ? `${props.height - 6}px` : `${height - 6}px`};
  width: ${(props) =>
    props.width ? `${props.width - 6}px` : `${width - 6}px`};
  padding: ${({ padding }) => (padding ? padding : null)};
  background: ${({ theme }) => theme.mainGradient};
  border: none;
  color: ${({ theme }) => theme.fontColor};
  //clip-path: ${({ theme }) => theme.clipPath};
  border-radius: ${({ theme }) => theme.buttonRadius};
  font: ${({ theme }) => theme.font.button};
  position: absolute;
  inset: 3px;
  cursor: pointer;
  isolation: isolate;

  &:after {
    content: '';
    border-radius: ${({ theme }) => theme.buttonRadius};
    background: url('/images/paterns.svg');
    filter: contrast(260%);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  &:hover {
    background: ${({ theme }) => theme.hoverGradient};
  }
`;
