import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Auth } from 'aws-amplify';
import { CloseBtn, Container, Main } from './LoginPage.styles';
import { Icon } from '../../components/Icons/BaseIcon';
import useForm from '../../utils/useForm';
import { validateLogin } from './validate';
import Spinner from '../../components/Spinner/Spinner';
import StyledInput from '../../components/Input/Input.component';
import StdButton from '../../components/StdButton/StdButton.component';
import { NavLink, useHistory } from 'react-router-dom';
import { HandleOutsideClick } from '../../utils/utils';

const LoginPage = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const { ref, isComponentVisible } = HandleOutsideClick();
  const { sendClose } = props;
  const { values, errors, handleSubmit, handleChange, setErrors } = useForm(
    submit,
    validateLogin,
    { email: '', password: '' }
  );

  const history = useHistory();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (!isComponentVisible) {
      sendClose();
    }
  }, [isComponentVisible, sendClose]);

  async function submit() {
    setShowSpinner(true);
    try {
      await Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          email: values.email,
          given_name: values.email.split('@')[0],
          family_name: ' ',
          'custom:terms': 'agreed',
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      });
    } catch (error) {
      setShowSpinner(false);
      if (error?.message?.includes('given email already exists')) {
        // Log User In
        try {
          const res = await Auth.signIn(values.email, values.password);
          if (res?.challengeName === 'NEW_PASSWORD_REQUIRED') {
            console.log(res);
            history.push({ pathname: '/password-change', userIn: res });
          }
          sendClose();
        } catch (error) {
          if (error?.message?.includes('Incorrect username or password')) {
            setErrors((prev) => ({
              ...prev,
              email: 'Invalid email or password',
            }));
          }
        }
      }
      if (error?.message?.includes('Google Login')) {
        setErrors((prev) => ({
          ...prev,
          email: 'Account setup with Google. Please log in through Google',
        }));
      }
      if (error?.message?.includes('Incorrect username or password')) {
        setErrors((prev) => ({
          ...prev,
          email: 'Invalid email or password',
        }));
      }
    } finally {
      setShowSpinner(false);
    }
  }

  return ReactDOM.createPortal(
    <Container>
      {showSpinner && <Spinner />}
      <Main ref={ref}>
        <CloseBtn onClick={sendClose} />
        <p className='title'>LOG IN</p>
        <p>Welcome back to Legends of Crypto.</p>
        <p>Please log in to continue.</p>
        <form onSubmit={handleSubmit}>
          <StyledInput
            name='email'
            id='email'
            type='text'
            value={values.email}
            errors={errors.email}
            placeholder='Email'
            autoComplete='off'
            onChange={handleChange}
            width='300px'
          />
          <StyledInput
            name='password'
            id='password'
            value={values.password}
            errors={errors.password}
            type={showPass ? 'text' : 'password'}
            placeholder='Password'
            autoComplete='off'
            onChange={handleChange}
            width='300px'
            showIcon={true}
            togglePassword={() => setShowPass(!showPass)}
            showPassword={showPass}
          />
          <StdButton
            width={300}
            onClick={handleSubmit}
            disabled={!/\S+@\S+\.\S+/.test(values.email)}
          >
            Log In
          </StdButton>
          <div className='split'>
            <p className='split'>OR</p>
          </div>
        </form>
        <div className='socials'>
          {/* <StdButton
            width={140}
            justify='flex-start'
            background='rgba(255,255,255,.03)'
            onClick={() => {
              setShowSpinner(true);
              Auth.federatedSignIn({ provider: 'picketapi.com' });
            }}
          >
            <Icon src='/images/wallet.svg' height={20} width={20} />
            Wallet Sign In
          </StdButton> */}
          <StdButton
            width={'100%'}
            justify='center'
            background='rgba(255,255,255,.03)'
            onClick={() => {
              setShowSpinner(true);
              Auth.federatedSignIn({ provider: 'Google' });
            }}
          >
            <Icon src='/images/google.svg' height={20} width={20} />
            Google Sign In
          </StdButton>
        </div>
        <p className='footer'>
          Need an account?{' '}
          <NavLink to='/signup' onClick={() => sendClose()}>
            <span className='link'>Sign Up</span>
          </NavLink>
        </p>
        <p className='footer'>
          <NavLink to='/password-reset' onClick={() => sendClose()}>
            <span className='link'>Forgot Password?</span>
          </NavLink>
        </p>
      </Main>
    </Container>,
    document.getElementById('portal')
  );
};

export default LoginPage;
