import React from 'react';
import { Container } from './Footer.styles';
import { Icon } from '../Icons/BaseIcon';

const Footer = () => {
  return (
    <Container>
      <ul>
        <li>
          <img
            src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618d462ee88384dcbe751fd9_NAv%20Logo.svg'
            alt='Logo'
            className='footer-logo'
          />
        </li>
        <li>
          <a href='https://market.locgame.io/support' alt='Privacy Policy'>
            support
          </a>
        </li>
        <li>
          <a href='https://www.locgame.io/company' alt='Privacy Policy'>
            about us
          </a>
        </li>
        <li>
          <a href='https://www.locgame.io/privacy-policy' alt='Privacy Policy'>
            Privacy Policy
          </a>
        </li>
      </ul>
      <ul>
        <li>
          {' '}
          <a href='https://medium.com/locgame' target='_blank' rel='noreferrer'>
            <div className='icon-wrapper'>
              <Icon
                src='https://assets.website-files.com/624c19382a0b70b62a24bb24/6262b35c92f0fa3f5b595efb_medium.png'
                margin='0'
              />
            </div>
          </a>
        </li>
        <li>
          <a href='https://t.me/loc_game' target='_blank' rel='noreferrer'>
            <div className='icon-wrapper'>
              <Icon
                src='https://assets.website-files.com/624c19382a0b70b62a24bb24/6262b278e9690eea9c219dd0_telegram.png'
                margin='0'
              />
            </div>
          </a>
        </li>
        <li>
          <a
            href='https://discord.com/invite/aPf6MTqTAj'
            target='_blank'
            rel='noreferrer'
          >
            <div className='icon-wrapper'>
              <Icon
                src='https://assets.website-files.com/624c19382a0b70b62a24bb24/6262b1bed1a9ff2d6efb2cee_discord.png'
                margin='0'
              />
            </div>
          </a>
        </li>
        <li>
          <a
            href='https://twitter.com/LOCgameio?s=20'
            target='_blank'
            rel='noreferrer'
          >
            <div className='icon-wrapper'>
              <Icon
                src='https://assets.website-files.com/624c19382a0b70b62a24bb24/624c19382a0b70db0e24bcf8_icons8-twitter-100.png'
                margin='0'
              />
            </div>
          </a>
        </li>
      </ul>
    </Container>
  );
};

export default React.memo(Footer);
