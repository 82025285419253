import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const Main = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background: ${({ theme }) => theme.bgColor};
  margin-top: ${({ theme }) => theme.navHeight};
  padding: 3rem 2rem;
  justify-content: center;
  width: 365px;

  .title {
    font-size: 20px;
    margin: 0.5rem 0;
  }

  p {
    line-height: 22px;
  }

  .split {
    width: 300px;
    height: 40px;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font: ${({ theme }) => theme.font.body};
    }
  }

  .socials {
    display: flex;
    width: 300px;
    justify-content: space-between;

    button {
      font-size: 12px !important;
      border: 1px solid white;
      font-weight: 300;
    }
  }

  .footer {
    width: 300px;
    text-align: center;
    font-size: 14px;
    margin-top: 0.5rem;

    .link {
      color: ${({ theme }) => theme.fontMuted};
      margin: 0 0.25rem;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 400px) {
    justify-content: center;
    width: 100%;
  }
`;

export const CloseBtn = styled.div`
  height: 25px;
  width: 25px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 13px;
  right: 17px;
  color: #5e5877;
  background-image: url('/images/close-circle.svg');
  background-size: cover;
  cursor: pointer;
`;
