/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container } from './Search.styles';
import { Icon } from '../Icons/BaseIcon';

const Search = (props) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    props.setFilters({ ...props.filters, search: value });
  }, [value]);

  return (
    <Container {...props}>
      <input
        autoComplete='off'
        type='text'
        name='search'
        id='search'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onFocus={() => (props.combineCards ? props.combineCards() : () => {})}
        placeholder={props.placeholder || 'Search'}
      />
      <Icon src='/images/loupe.svg' height={20} width={20} margin={0} />
    </Container>
  );
};

export default Search;
