import React, { useState } from 'react';
import { Icon } from '../Icons/BaseIcon';
import { Container } from './Filters.style';
import Checkbox from '../Checkbox/Checkbox.component';
import RangePicker from '../RangePicker/RangePicker.component';

const Filters = (props) => {
  const {
    filters,
    setFilters,
    cards,
    resetFilters,
    setShowFilter,
    sortOptions,
  } = props;
  const [stats, setStats] = useState(false);
  const [gp, setGp] = useState(false);
  const [visual, setVisual] = useState(false);
  const [packs, setPacks] = useState(false);

  const handleChange = (e) => {
    e.stopPropagation();
    let { name, value } = e.target;
    if (name === 'sort') {
      setFilters({ ...filters, sort: value });
    } else {
      if (value === 'false') setFilters({ ...filters, [name]: true });
      else setFilters({ ...filters, [name]: false });
    }
  };

  const getSortOptions = () => {
    if (!sortOptions) return;
    let keys = Object.keys(sortOptions);
    return keys.map((key) => (
      <option key={key} value={key}>
        {sortOptions[key]}
      </option>
    ));
  };

  return (
    <Container {...props}>
      {filters && (
        <>
          <div className='top-row'>
            <div>
              <h6>Sort </h6>
              <select
                name='sort'
                id='min'
                onChange={handleChange}
                value={filters.sort}
              >
                {sortOptions ? (
                  getSortOptions()
                ) : (
                  <>
                    <option value='newest'>Newest</option>
                    <option value='oldest'>Oldest</option>
                  </>
                )}
              </select>
            </div>
            <div className='filter-controller'>
              <p className='reset' onClick={() => setShowFilter(false)}>
                Close
              </p>
              <p className='reset' onClick={resetFilters}>
                Reset Filters
              </p>
            </div>
          </div>
          <div className='filter-row'>
            <div className='column'>
              <div
                className={stats ? 'col-header open' : 'col-header'}
                onClick={() => setStats(!stats)}
              >
                <h6>Card Stats</h6>
                <Icon
                  src='/images/line-arrow-right.svg'
                  height={16}
                  width={16}
                  margin='0'
                />
              </div>
              <div className={stats ? 'options open' : 'options'}>
                <div className='filter-row'>
                  <div className='column'>
                    <RangePicker
                      inline='true'
                      iconHeight='19'
                      iconWidth='33'
                      iconSrc='/images/arrowUp.png'
                      filters={filters}
                      setFilters={setFilters}
                      cards={cards}
                      name='influence'
                    />
                    <RangePicker
                      inline='true'
                      iconHeight='19'
                      iconWidth='33'
                      iconSrc='/images/arrowDown.png'
                      filters={filters}
                      setFilters={setFilters}
                      cards={cards}
                      name='devSkills'
                    />
                  </div>
                  <div className='column'>
                    <RangePicker
                      inline='true'
                      iconHeight='33'
                      iconWidth='19'
                      iconSrc='/images/arrowLeft.png'
                      filters={filters}
                      setFilters={setFilters}
                      name='innovation'
                    />
                    <RangePicker
                      inline='true'
                      iconHeight='33'
                      iconWidth='19'
                      iconSrc='/images/arrowRight.png'
                      filters={filters}
                      setFilters={setFilters}
                      cards={cards}
                      name='community'
                    />
                  </div>
                  <div className='column'>
                    <RangePicker
                      inline='true'
                      iconHeight='30'
                      iconWidth='32'
                      iconSrc='/images/hp.png'
                      filters={filters}
                      setFilters={setFilters}
                      name='health'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='filter-row'>
            <div className='column'>
              <div
                className={packs ? 'col-header open' : 'col-header'}
                onClick={() => setPacks(!packs)}
              >
                <h6>Packs</h6>
                <Icon
                  src='/images/line-arrow-right.svg'
                  height={16}
                  width={16}
                  margin='0'
                />
              </div>
              <div className={packs ? 'options open' : 'options'}>
                <div className='filter-row'>
                  <Checkbox
                    inline='true'
                    name='genesis'
                    id='genesis'
                    label='Genesis'
                    value={filters.genesis}
                    checked={filters.genesis}
                    onChange={handleChange}
                    className='filter-check'
                  />
                  <Checkbox
                    inline='true'
                    name='blockchain'
                    id='blockchain'
                    label='Blockchain Founders'
                    value={filters.blockchain}
                    checked={filters.blockchain}
                    onChange={handleChange}
                    className='filter-check'
                  />
                  <Checkbox
                    inline='true'
                    name='cryptoCeleb'
                    id='cryptoCeleb'
                    label='Crypto Celebrity'
                    value={filters.cryptoCeleb}
                    checked={filters.cryptoCeleb}
                    onChange={handleChange}
                    className='filter-check'
                  />
                  <Checkbox
                    inline='true'
                    name='defi'
                    id='defi'
                    label='DeFi'
                    value={filters.defi}
                    checked={filters.defi}
                    onChange={handleChange}
                    className='filter-check'
                  />
                  <Checkbox
                    inline='true'
                    name='metaverse'
                    id='metaverse'
                    label='Metaverse'
                    value={filters.metaverse}
                    checked={filters.metaverse}
                    onChange={handleChange}
                    className='filter-check'
                  />
                  <Checkbox
                    inline='true'
                    name='mcw'
                    id='mcw'
                    label='Michael Carter-Williams'
                    value={filters.mcw}
                    checked={filters.mcw}
                    onChange={handleChange}
                    className='filter-check'
                  />
                  <Checkbox
                    inline='true'
                    name='apollo'
                    id='apollo'
                    label='Apollo'
                    value={filters.apollo}
                    checked={filters.apollo}
                    onChange={handleChange}
                    className='filter-check'
                  />
                  <Checkbox
                    inline='true'
                    name='djsoda'
                    id='djsoda'
                    label='DJ Soda'
                    value={filters.djsoda}
                    checked={filters.djsoda}
                    onChange={handleChange}
                    className='filter-check'
                  />
                  <Checkbox
                    inline='true'
                    name='wolf'
                    id='wolf'
                    label='Wolf of Crypto'
                    value={filters.wolf}
                    checked={filters.wolf}
                    onChange={handleChange}
                    className='filter-check'
                  />
                  <Checkbox
                    inline='true'
                    name='sacrifice'
                    id='sacrifice'
                    label='Sacrifice'
                    value={filters.sacrifice}
                    checked={filters.sacrifice}
                    onChange={handleChange}
                    className='filter-check'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='filter-row'>
            <div className='column'>
              <div
                className={visual ? 'col-header open' : 'col-header'}
                onClick={() => setVisual(!visual)}
              >
                <h6>Visual Rarity</h6>
                <Icon
                  src='/images/line-arrow-right.svg'
                  height={16}
                  width={16}
                  margin='0'
                />
              </div>
              <div className={visual ? 'options open' : 'options'}>
                <div className='filter-row'>
                  <Checkbox
                    inline='true'
                    name='standard'
                    id='standard'
                    label='Standard'
                    value={filters.standard}
                    checked={filters.standard}
                    onChange={handleChange}
                    className='filter-check'
                  />
                  <Checkbox
                    inline='true'
                    name='prestige'
                    id='prestige'
                    label='Prestige'
                    value={filters.prestige}
                    checked={filters.prestige}
                    onChange={handleChange}
                    className='filter-check'
                  />
                  <Checkbox
                    inline='true'
                    name='elite'
                    id='elite'
                    label='Elite'
                    value={filters.elite}
                    checked={filters.elite}
                    onChange={handleChange}
                    cards={cards}
                    className='filter-check'
                  />
                  <Checkbox
                    inline='true'
                    name='superElite'
                    id='superElite'
                    label='Super Elite'
                    value={filters.superElite}
                    checked={filters.superElite}
                    onChange={handleChange}
                    cards={cards}
                    className='filter-check'
                  />
                  <Checkbox
                    inline='true'
                    name='legend'
                    id='legend'
                    label='Legendary'
                    value={filters.legend}
                    checked={filters.legend}
                    onChange={handleChange}
                    cards={cards}
                    className='filter-check'
                  />
                </div>
              </div>
            </div>
            <div className='column'>
              <div
                className={gp ? 'col-header open' : 'col-header'}
                onClick={() => setGp(!gp)}
              >
                <h6>Gameplay Rarity</h6>
                <Icon
                  src='/images/line-arrow-right.svg'
                  height={16}
                  width={16}
                  margin='0'
                />
              </div>
              <div className={gp ? 'options open' : 'options'}>
                <Checkbox
                  inline='true'
                  name='common'
                  id='common'
                  label='Common'
                  value={filters.common}
                  checked={filters.common}
                  onChange={handleChange}
                  cards={cards}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Filters;
