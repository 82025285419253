/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Container } from '../Page.styles';
import Filters from '../../components/Filters/Filters.component';
import { Header, Section, Main } from './CardLibrary.styles';
import useFilter from '../../utils/useFilter';
import GameCard from '../../components/GameCard/GameCard.component';
import Search from '../../components/Search/Search.component';
import LibraryHeader from '../../components/LibraryHeader/LibraryHeader.component';
import useResizeObserver from '../../utils/useResizeObserver';
import { getCards } from '../../utils/api';

const CardLibrary = (props) => {
  const itemWidth = 287;
  const [cards, setCards] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [headerWidth, setHeaderWidth] = useState();
  const { filters, setFilters, filteredData, resetFilters } = useFilter(
    cards,
    'card'
  );

  const sectionRef = useRef();
  const dimensions = useResizeObserver(sectionRef);

  useEffect(() => {
    getCards().then((data) => setCards(data));
    if (window.innerWidth < 950) {
      setShowFilter(false);
    }
  }, []);

  useEffect(() => {
    if (!dimensions) return;
    let cardsToFit = Math.ceil(dimensions.width / itemWidth);
    setHeaderWidth(itemWidth * (cardsToFit - 1));
  }, [dimensions]);

  return (
    <>
      <LibraryHeader />
      {filteredData && (
        <Container>
          <Filters
            showFilter={showFilter}
            mobile={true}
            layout='vertical'
            filters={filters}
            setFilters={setFilters}
            cards={cards}
            resetFilters={resetFilters}
            setShowFilter={setShowFilter}
          />
          <Main>
            <Section>
              <Header margin='0' padding='0 2rem' maxWidth={headerWidth}>
                <div className='filter-wrapper'>
                  <h4>{filteredData.length} Cards </h4>
                  <div
                    className='filterMenu'
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                  >
                    Filters {showFilter ? <span className='close'>X</span> : ''}
                  </div>
                </div>
                <Search
                  filters={filters}
                  setFilters={setFilters}
                  resetFilters={resetFilters}
                  //combineCards={combineCards}
                />
              </Header>
            </Section>
            <Section ref={sectionRef}>
              <div className='content'>
                {filteredData.map((card) => (
                  <div key={card._id} className='card'>
                    <GameCard key={card.tokenId} card={card} />
                  </div>
                ))}
              </div>
            </Section>
          </Main>
        </Container>
      )}
    </>
  );
};

export default CardLibrary;
