/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, IconDiv } from './RangePicker.styles';
import { Icon } from '../Icons/BaseIcon';

const RangePicker = (props) => {
  const [range, setRange] = useState(props.filters[props.name]);

  const valueChange = (e) => {
    const { name, value } = e.target;
    setRange({ ...range, [name]: value });
  };

  useEffect(() => {
    props.setFilters({ ...props.filters, [props.name]: range });
  }, [range]);

  useEffect(() => {
    setRange(props.filters[props.name]);
  }, [props.filters[props.name]]);

  return (
    <Container {...props}>
      <IconDiv {...props}>
        <Icon
          src={props.iconSrc}
          height={props.iconHeight}
          width={props.iconWidth}
        />
      </IconDiv>

      <div>
        <select
          name='min'
          id='min'
          onChange={valueChange}
          value={props.filters[props.name].min}
        >
          <option value='0'>0</option>
          <option value='10'>10</option>
          <option value='20'>20</option>
          <option value='30'>30</option>
          <option value='40'>40</option>
          <option value='50'>50</option>
          <option value='60'>60</option>
          <option value='70'>70</option>
          <option value='80'>80</option>
          <option value='90'>90</option>
          <option value='100'>100</option>
        </select>
        <select
          name='max'
          id='max'
          onChange={valueChange}
          value={props.filters[props.name].max}
        >
          <option disabled value='max'>
            Max
          </option>
          <option value='10'>10</option>
          <option value='20'>20</option>
          <option value='30'>30</option>
          <option value='40'>40</option>
          <option value='50'>50</option>
          <option value='60'>60</option>
          <option value='70'>70</option>
          <option value='80'>80</option>
          <option value='90'>90</option>
          <option value='100'>100</option>
        </select>
      </div>
    </Container>
  );
};

export default RangePicker;
