import styled from 'styled-components';

export const Container = styled.div`
  display: ${({ showFilter }) => (showFilter ? 'flex' : 'none')};
  flex-wrap: wrap;
  flex-direction: column;
  background: ${({ theme }) => theme.bgColor};
  width: 360px;
  ${(props) =>
    props.layout === 'vertical'
      ? `border-right: 1px solid ${props.theme.primaryColor}`
      : `border-bottom: 1px solid ${props.theme.primaryColor}`};
  padding: 2rem 1rem;
  animation: slide 500ms forwards;
  position: absolute;
  top: ${({ top }) => (top ? top : '0')};
  left: 0;
  z-index: 10;

  h3,
  h4,
  h6 {
    margin: 0 0.75rem 0 0;
    padding: 5px 0;
  }

  select {
    display: inline-block;
    //appearance: none;
    margin: 0 1rem 0 0;
    //width: 10ch;
    background: none;

    border: ${({ theme }) => theme.borderMuted};
    border-radius: 4px;
    color: ${({ theme }) => theme.fontColor};
    padding: 8px;
    font: ${({ theme }) => theme.font.small};
  }

  option {
    background: ${({ theme }) => theme.primaryColor};
  }

  .col-header {
    display: flex;
    width: 255px;
    margin-top: 1rem;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    img {
      transform: rotate(-90deg);
    }

    &.open {
      img {
        transform: rotate(90deg);
      }
    }
  }

  .options {
    height: 0;
    overflow: hidden;

    &.open {
      height: unset;
    }
  }

  .column {
    min-width: 255px;
  }

  .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
    }
    h5 {
      margin-right: 2rem;
    }
    .reset {
      font: ${({ theme }) => theme.font.small};
      cursor: pointer;
      color: ${({ theme }) => theme.lightBlue};
      padding: 3px;
    }
    .filter-controller {
      width: 100px;
      display: block !important;
    }
  }

  .filter-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .filter-check {
      margin-right: 1rem;
    }
    .column {
      max-width: 398px;
      margin: 0 10px;
    }
  }

  @keyframes slide {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
