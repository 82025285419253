import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 0;
  border-top: 1px solid rgba(242, 243, 245, 0.07);
  margin-top: 3rem;
  ul {
    display: flex;
    list-style: none;
  }
  li {
    margin-right: 30px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    a {
      color: ${({ theme }) => theme.fontColor};
      opacity: 0.6;
      transition: opacity 200ms ease, transform 200ms linear,
        -webkit-transform 200ms linear;
      &:hover {
        opacity: 1;
      }
    }
  }

  .footer-logo {
    width: 40px;
  }

  .icon-wrapper {
    opacity: 0.6;
    transition: opacity 200ms ease, transform 200ms linear,
      -webkit-transform 200ms linear;

    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1200px) {
    padding-left: 2rem;
  }
  @media screen and (max-width: 649px) {
    flex-direction: column-reverse;
    ul {
      margin-bottom: 1rem;
    }
  }
`;
